<template>
  <div class="footer">
    <div class="footer__container">
      <div class="footer__icons">
        <!-- TODO: v-for icons -->
        <a :href="githubLink" target="_blank" class="footer__icon">
          <img alt="github" width="24" height="24" src="../assets/social-media/github.svg"/>
        </a>
        <!-- <a :href="twitterLink" target="_blank" class="footer__icon">
          <img width="24" height="24"  src="../assets/social-media/twitter.svg"/>
        </a> -->
        <a :href="mediumLink" target="_blank" class="footer__icon">
          <img alt="medium" width="24" height="24"  src="../assets/social-media/medium.svg"/>
        </a>
        <!-- <a :href="telegramLink" target="_blank" class="footer__icon">
          <img width="24" height="24"  src="../assets/social-media/telegram.svg"/>
        </a> -->
      </div>
      <div class="footer__links">
        <a class="footer__link" href="https://storage.googleapis.com/optics-audits/FTI%20Smart%20Contracts%20Assessment%20Report_Optics-Core_20211008_v2.pdf" target="_blank">Audit</a>
        <a class="footer__link" :href="docsLink" target="_blank">Docs</a>
        <a class="footer__link" :href="supportLink" target="_blank">Support</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  githubLink,
  supportLink,
  twitterLink,
  mediumLink,
  telegramLink,
  docsLink
} from '../i18n/main.json'

export default defineComponent({
  data () {
    return {
      githubLink,
      supportLink,
      twitterLink,
      mediumLink,
      telegramLink,
      docsLink
    }
  }
})
</script>

<style scoped>
/* root classes */
.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--bg-dark);
  border-top: 1px solid rgba(255, 255, 255, 0.09);
  padding: 0 10px;
  font-family: sans-serif;
}
.footer__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 20px 40px;
}

/* icons */
.footer__icons {
  display: flex;
  flex-direction: row;
}
.footer__icon {
  height: 24px;
  margin-right: 30px;
}

/* text links */
.footer__links {
  display: flex;
  flex-direction: row;
}
.footer__link {
  margin-left: 50px;
  color: #E86DA1;
  font-size: 1.1em;
  font-weight: 200;
  letter-spacing: 5px;
  text-decoration: none;
}

/* mobile */
@media only screen and (max-width: 600px) {
  .footer {
    height: 120px;
  }
  .footer__icon {
    margin: 0 20px;
  }
  .footer__container {
    flex-direction: column;
  }
  .footer__link {
    margin: 0 10px;
    font-size: 20px;
  }
}
</style>
