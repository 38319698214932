<template>
  <n-config-provider
    :theme="darkTheme"
    :theme-overrides="themeOverrides"
    class="container"
  >
    <NavigationBar/>
    <Popup-List/>
    <!-- <Alert/> -->
    <div class="body">
      <!-- loading -->
      <div v-if="loading || networkUnsupported">
        <Logo-Spin/>
      </div>

      <Modal v-else/>
    </div>
    <FooterElement class="footer"/>
  </n-config-provider>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { NConfigProvider, darkTheme } from 'naive-ui'
import { BigNumber } from 'ethers'

import NavigationBar from './components/Nav.vue'
import Modal from './components/Modal.vue'
import FooterElement from './components/Footer.vue'
import LogoSpin from './components/LogoSpin.vue'
import PopupList from './components/Alerts/PopupList.vue'
import { useStore } from './store'
import { getNetworkByChainID } from './utils'

const themeOverrides = {
  common: {
    secondaryColor: '#E24084',
    secondaryColorHover: '#B14EA6'
  },
  Button: {
    textColorBase: '#FFF',
    primaryColor: '#C94079',
    baseColor: '#C94079'
  }
}

export default defineComponent({
  components: {
    NConfigProvider,
    NavigationBar,
    Modal,
    LogoSpin,
    FooterElement,
    PopupList
  },
  data () {
    return {
      darkTheme,
      themeOverrides
    }
  },
  created () {
    const store = useStore()
    store.dispatch('fetchSanctionedList')
  },
  setup () {
    const store = useStore()
    const { ethereum } = window as any

    store.dispatch('instantiateOptics')

    setTimeout(() => {
      if (ethereum) {
        const connected = ethereum.isConnected()
        if (connected) {
          store.dispatch('connectWallet')
        }
      }
    }, 3000)

    setInterval(() => {
      if (store.state.wallet.connected) {
        store.dispatch('getBalanceFromWallet')
      }
    }, 10000)

    // listens for user chain change event
    if (ethereum) {
      ethereum.on('chainChanged', async (chainId: number) => {
        console.log('network change')

        try {
          // get name of network and set in store
          const id = BigNumber.from(chainId).toNumber()
          const network = getNetworkByChainID(id).name
          await store.dispatch('setWalletNetwork', getNetworkByChainID(id).name)

          // update signer
          store.dispatch('registerSigner', network.toLowerCase())
        } catch (e) {
          store.dispatch('setNetworkUnsupported', true)
          console.error(e)
        }
        await store.dispatch('getBalanceFromWallet')
      })
      ethereum.on('accountsChanged', () => {
        // everything changes, easiest to reload
        location.reload()
      })
    }

    return {
      loading: computed(() => !store.state.wallet.connected),
      networkUnsupported: computed(() => store.state.wallet.networkUnsupported)
    }
  }
})
</script>

<style>
#app {
  width: 100%;
  overflow: hidden;
}
:root {
  --bg-black: rgb(10, 10, 10);
  --bg-dark: rgb(0, 0, 0);
  --text-muted: rgba(255, 255, 255, 0.5);
  --spacer: 30px;
  --mobile-width: 700px;
  --body-height: calc(100vh - 243px);
}

body {
  background-color: var(--bg-black);
}

.body {
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  height:100%;
  padding: 60px 20px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 122px;
  min-height: calc(100vh - 116px);
}

.capitalized {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.link {
  color: #E24084
}

/* mobile */
@media only screen and (max-width: 600px) {
  .body {
    padding: 0
  }
}
</style>
