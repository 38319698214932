<template>
  <n-form
    ref="formRef"
    :model="formModel"
  >
    <div id="form-inputs" :class="{ hidden: sending }">
      <Disclaimer/>
      <!-- here's the form -->
      <n-card :title="headerFrom">
        <!-- FROM NETWORK: Select <asset> on <network -->
        <div class="asset-and-ognetwork">
          <Asset-Logo-Icon :asset="formModel.asset" :assetIcon="walletToken.icon"/>

          <!-- FROM: ASSET -->
          <n-select
            class="asset-and-ognetwork__select"
            placeholder="Select Asset"
            :options="assetOptions"
            :value="formModel.asset"
            :disabled="sending"
            @update:value="handleAssetChange"
          />

          <p class="asset-and-ognetwork__on">on</p>
          <!-- FROM: ORIGIN NETWORK -->
          <n-select
            class="network__select capitalized"
            placeholder="Select Origin Network"
            :options="originNetworkOptions"
            :value="formModel.originNetwork"
            :disabled="sending"
            @update:value="handleOriginNetworkChange"
          />
        </div>

        <!-- SEND AMOUNT -->
        <Balance-And-Send-Amt/>
      </n-card>
      <hr class="divider">

      <n-card :title="headerTo">
        <!-- TO: DESTINATION NETWORK -->
        <n-select
          class="to-network__select"
          :placeholder="toNetworkDefault"
          :value="formModel.destinationNetwork"
          :disabled="sending"
          :options="destinationNetworkOptions"
          @update:value="handleDestNetworkChange"
        />

        <!-- TO: DESTINATION ADDRESS -->
        <Recipient-Address :key="formModel.destinationNetwork"/>
      </n-card>
    </div>

    <div
      v-if="sending"
      class="sending-animation"
    >
      <Logo-Wheel/>
    </div>

    <!-- SEND BUTTON -->
    <Send/>
  </n-form>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { NCard, NSelect, NForm } from 'naive-ui'

import AssetLogoIcon from './AssetLogoIcon.vue'
import Disclaimer from './Disclaimer.vue'
import BalanceAndSendAmt from './BalanceAndSendAmt.vue'
import Send from './Send.vue'
import RecipientAddress from './RecipientAddress.vue'
import LogoWheel from '../LogoWheel.vue'
import { useStore } from '../../store'
import * as copy from '../../i18n/main.json'
import {
  generateTokenOptions,
  generateNetworkOptions,
  destinationNetworks,
  getTokenByName,
  getRepresentationalAssetFromNative
} from '../../utils'

export default defineComponent({
  components: {
    NCard,
    NSelect,
    NForm,
    AssetLogoIcon,
    Disclaimer,
    BalanceAndSendAmt,
    RecipientAddress,
    Send,
    LogoWheel
  },

  data () {
    return {
      // user should not be able to accept same network as current/from network
      destinationNetworkOptions: destinationNetworks(this.originNetworkOptions, this.walletNetwork),

      // copy
      headerFrom: copy.headerFrom,
      headerTo: copy.headerTo,
      toNetworkDefault: copy.toNetworkDefault
    }
  },

  setup () {
    const store = useStore()
    const walletAddress = computed(() => store.state.wallet.address)
    const walletNetwork = computed(() => store.state.wallet.network)
    const assetOptions = computed(() => generateTokenOptions(store.state.wallet.network))
    const walletToken = computed(() => store.state.wallet.token)
    const walletTokenName = computed(() => store.state.wallet.token.name)
    const sendAmount = computed(() => store.state.userInput.sendAmount)
    const destinationNetwork = computed(() => store.state.userInput.destinationNetwork)
    const sending = computed(() => store.state.multiProvider.sending)

    return {
      formRef: ref(null),
      formModel: ref({
        asset: walletTokenName,
        originNetwork: walletNetwork,
        destinationNetwork,
        sendAmount,
        recipientAddress: walletAddress.value
      }),
      assetOptions,
      originNetworkOptions: generateNetworkOptions(),
      recipientAddress: computed(() => store.state.userInput.recipientAddress),
      walletNetwork,
      walletToken,
      sending,
      store
    }
  },

  methods: {
    handleAssetChange (newAsset: string) {
      const token = getTokenByName(newAsset)
      // TODO: Make setToken wait until balance is retrieved before changing
      // so that there isn't a weird flash
      // OR turn balance into spinning icon while balance is retrieved
      this.store.dispatch('setToken', token)
    },

    async handleOriginNetworkChange (originNetwork: string) {
      await this.store.dispatch('setWalletNetwork', originNetwork)

      // user should not be able to select same destination network as from network
      this.destinationNetworkOptions = destinationNetworks(this.originNetworkOptions, this.walletNetwork)
      if (this.walletNetwork === this.formModel.destinationNetwork) {
        this.store.dispatch('clearDestinationNetwork')
      }

      // user should not be able to access native ETH assets on non-native networks
      const assetFound = this.assetOptions.find(o => o.value === this.formModel.asset)
      if (!assetFound) {
        // native asset is not an option on current network
        // switch to representational asset and alert user
        const token = getRepresentationalAssetFromNative(this.formModel.asset)
        const payload = { message: `${this.formModel.asset} is a native asset that doesn't exist on ${originNetwork}. Asset switched to ${token.name}.` }
        this.store.dispatch('addInfoPopup', payload)
        this.store.dispatch('setToken', token)
      }
    },

    async handleDestNetworkChange (destNetwork: string) {
      await this.store.dispatch('setDestinationNetwork', destNetwork)
    }
  }
})
</script>

<style scoped>
/* sending animation */
#form-inputs {
  /* must be relative so logo animation can be positioned accurately */
  position: relative;
}
.hidden {
  visibility: hidden;
}
.sending-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* from asset row */
.asset-and-ognetwork {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--spacer);
}
.asset-and-ognetwork__select {
  width: 250px;
}
.asset-and-ognetwork__on {
  margin: 0px 10px;
  flex-shrink: 0;
}

/* hr divider */
.divider {
  height: 1px;
  background-color: var(--text-muted);
  border: none;
  margin: var(--spacer) 0px;
}

/* destination inputs */
.to-network__select {
  margin-bottom: var(--spacer);
}

/* approve/send buttons */
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: var(--spacer);
}
.buttons__send {
  width: 150px;
}
.send__tooltip {
  max-width: 220px !important;
}
</style>
