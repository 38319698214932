import { BigNumber, ethers } from 'ethers'

import { networks, tokens } from '@/config'
import { NetworkMetadata, TokenMetadata } from '@/config/config.types'
import { SANCTIONED_ADDRESSES } from 'compliance-sdk'

// UI DISPLAY

/**
 * Shortens address for UI display
 * 0x0000...0000
 */
export function truncateAddr (addr: string): string {
  const first = addr.slice(0, 6)
  const len = addr.length
  const last = addr.slice(len - 4, len)
  return `${first}...${last}`
}

/**
 * Makes a BigNumber have # of decimals
 */
export function toDecimals (amnt: BigNumber, tokenDecimals: number, numDecimals?: number): string {
  const decimal = ethers.utils.formatUnits(amnt, tokenDecimals)
  if (decimal === '0.0') {
    return '0'
  }

  const index = decimal.indexOf('.')
  if (index === -1) {
    return decimal
  }

  const end = index + (numDecimals || 18) + 1
  return decimal.slice(0, end)
}

// loops over list of tokens to create select options
export function generateTokenOptions (network: string) {
  const options = []
  for (const t in tokens) {
    const token = tokens[t]
    if (!token.nativeOnly || token.nativeNetwork.toLowerCase() === network.toLowerCase()) {
      options.push({
        label: token.name,
        value: token.name,
        key: token.name
      })
    }
  }
  return options
}

// loops over list of networks to create select options
export function generateNetworkOptions () {
  return Object.keys(networks).map(n => {
    const name = n.charAt(0).toUpperCase() + n.slice(1)
    return {
      label: name,
      value: name,
      key: name
    }
  })
}

// loops over list of networks to create select options (excluding fromNetwork)
export function destinationNetworks (options: any, network: any) {
  const networks = options.filter((option: any) => {
    return option.label !== network
  })
  return networks
}

// NETWORK

/**
 * Retrieves network config given a chain ID
 */
export function getNetworkByChainID (chainID: number): NetworkMetadata {
  for (const network in networks) {
    if (networks[network].chainID === chainID) {
      return networks[network]
    }
  }
  throw new Error(`network not found: ${chainID}`)
}

/**
 * Retrieves network config given a domain ID
 */
export function getNetworkByDomainID (domainID: number): NetworkMetadata {
  for (const network in networks) {
    if (networks[network].domainID === domainID) {
      return networks[network]
    }
  }
  throw new Error(`network not found: ${domainID}`)
}

/**
 * Given a network name, return the domainID
 */
export function getNetworkDomainIDByName (networkName: string) {
  const network = networks[networkName.toLowerCase()]
  return network.domainID
}

// TOKEN

export function getTokenByName (name: string) {
  for (const t in tokens) {
    const token = tokens[t]
    if (token.name === name) {
      return token
    }
  }
  console.error('token asset not found')
}

export function getRepresentationalAssetFromNative (nativeTokenName: string): TokenMetadata {
  switch (nativeTokenName) {
    case 'Kovan ETH':
      return tokens.KWETH
    case 'Rinkeby ETH':
      return tokens.RWETH
    case 'ETH':
      return tokens.WETH
    case 'MATIC':
      return tokens.WMATIC
    default:
      return getTokenByName(nativeTokenName)!
  }
}

// determines if the token is native to the selected origin network
export function isNativeToken (network: string, token: TokenMetadata) {
  const nativeToken = networks[network.toLowerCase()].nativeToken
  return nativeToken.name === token.name
}

// VALIDATION

/**
 * Verifies address is valid (in any supported format)
 */
export function isValidAddress (address: string): boolean {
  try {
    return ethers.utils.isAddress(address)
  } catch (_e) {
    return false
  }
}

/**
 * Checks address against sanctioned address arrays
 */
export function isSanctionedAddress (...addresses: string[]): boolean {
  return addresses.some((address) =>
    (SANCTIONED_ADDRESSES as unknown as string[]).includes(address)
  )
}

export async function getMinAmount (coinId: string) {
  const res = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${coinId}&vs_currencies=usd`)
  const data = (await res.json()) as any
  const { usd } = data[coinId]
  // minimum dollar amount
  const dollarAmt = 10
  return dollarAmt / usd
}
