<template>
  <n-card v-if="txDetails" class="tx-details">
    <div class="header">
      <n-h3>Transaction Details</n-h3>
      <n-tag
        v-if="txDetails.status === 'Processed'"
        type="success"
        round
      >Processed</n-tag>
      <n-tag
        v-else-if="requiresManualProcessing"
        type="warning"
        round
      >Requires Manual Processing</n-tag>
      <n-tag
        v-else-if="healthy"
        type="warning"
        round
      >Processing | Healthy</n-tag>
      <n-tag
        v-else
        type="error"
        round
      >Processing | Delayed</n-tag>
    </div>
    <n-alert
      v-if="txDetails.status !== 'Processed' && !healthy"
      type="warning"
      class="alert"
    >{{ timeTooltipError[0] }}<a :href="supportLink" target="_blank" class="alert__link">{{ timeTooltipError[1] }}</a>{{ timeTooltipError[2] }}</n-alert>
    <div class="manual_process" v-if="requiresManualProcessing">
      <!-- TODO: Prompt metamask change network -->
        <span class="manual_process_text"> Transactions <strong>to</strong> Ethereum are no longer subsidized. Please complete the processing of the transfer on Ethereum yourself when the fraud window has expired (See below under "Time to Processing") </span>
        <n-button @click="triggerTXProcessing" v-if="readyForProcessing">
          Process Transaction
        </n-button>
    </div>

    <Transaction-Success :txHash="txDetails.hash"/>

    <div class="details">
      <n-card class="details__table">
        <div class="row">
          <p class="label">Amount:</p>
          <p>{{ txDetails.amount }} {{ txDetails.token }}</p>
        </div>

        <n-divider/>

        <div class="row">
          <p class="label">From:</p>
          <p class="capitalized">{{ txDetails.origin }}</p>
        </div>

        <n-divider/>

        <div class="row">
          <p class="label">To:</p>
          <p class="capitalized">{{ txDetails.destination }}</p>
        </div>

        <n-divider/>

        <div class="row">
          <p class="label">Time Sent:</p>
          <p v-if="!txDetails.timestamp">Data not available</p>
          <p v-else>
            {{ parseTimestamp(txDetails.timestamp).date }}
            <br>
            {{ parseTimestamp(txDetails.timestamp).time }}
          </p>
        </div>

        <n-divider/>

        <div class="row">
          <p class="label">Time to Processing:</p>
          <p>{{ confirmAt }}</p>
        </div>
      </n-card>

      <n-steps
        :current="current"
        vertical
        status="process"
        size="small"
        class="details__status"
      >
        <n-step v-for="(step, i) in steps" :key="i" :title="step.title" :description="step.time"/>
      </n-steps>
    </div>

  </n-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { NCard, NButton, NSteps, NStep, NDivider, NH3, NTag, NAlert } from 'naive-ui'
import { BigNumber } from 'ethers'

import TransactionSuccess from './TransactionSuccess.vue'
import { supportLink, timeTooltipError } from '@/i18n/main.json'
import { getHoursAgo, parseTimestamp } from '@/utils/time'
import { s3BucketUrl, requiresManualProcessing as requiresManualProcessingFromConfig } from '../../config'
import { useStore } from '../../store'

const requiresManualProcessing = requiresManualProcessingFromConfig || (() => false)

export default defineComponent({
  components: {
    NCard,
    NButton,
    NSteps,
    NStep,
    NDivider,
    NH3,
    NTag,
    NAlert,
    TransactionSuccess
  },

  props: {
    txDetails: Object
  },

  data () {
    return {
      steps: [{
        title: 'Dispatched',
        time: '(A few minutes)'
      }, {
        title: 'Included',
        time: '(Less than 1 hour)'
      }, {
        title: 'Relayed',
        time: '(1 hour)'
      }, {
        title: 'Processed',
        time: 'Funds have arrived'
      }],

      supportLink,
      timeTooltipError,
      proof: undefined
    }
  },

  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchProof()
  },

  setup () {
    const store = useStore()
    return { store }
  },

  methods: {
    getHoursAgo,
    parseTimestamp,
    triggerTXProcessing () {
      // TODO send tx to smart contract with the proof.
      const send = async () => {
        await this.store.dispatch('setWalletNetwork', this.txDetails!.destination)
        const replica = this.store.getters.getReplica(this.txDetails!.origin, this.txDetails!.destination)
        // @ts-ignore
        const tx = await replica.proveAndProcess(this.proof.message, this.proof.proof.path, this.proof.proof.index)
        await tx.wait(2)
        alert('Message processed!')
      }
      send()
    },
    fetchProof () {
      if (this.proof) {
        return
      }
      setTimeout(async () => {
        if (!this.txDetails) {
          return this.fetchProof()
        }
        if (requiresManualProcessing(this.txDetails!.destination)) {
          try {
            const proofURL = `${s3BucketUrl}${this.txDetails?.origin}_${this.txDetails!.leafIndex}`
            const response = await fetch(proofURL)
            if (!response.ok || response.status >= 400) {
              this.fetchProof()
              return
            }
            const proof = await response.json()
            this.proof = proof
          } catch (error) {
            console.error(error)
            this.fetchProof()
          }
        }
        // TODO How often?
      }, 2000)
    }
  },

  computed: {
    current () {
      switch (this.txDetails!.status) {
        case 'Dispatched':
          return 1
        case 'Included':
          return 2
        case 'Relayed':
          return 3
        case 'Processed':
          return 4
        default:
          return 0
      }
    },

    confirmAt () {
      const { confirmAt, status } = this.txDetails!
      // add 15 minutes
      const confirmationTime = confirmAt.add(BigNumber.from('54000')).toNumber()

      if (confirmAt.isZero()) {
        // tx has not been relayed, no confirmation time available
        return 'About 1 hour'
      } else if (status === 'Processed') {
        // tx has been confirmed and processed
        return 'Delivered'
      } else if (Date.now() > confirmationTime) {
        // tx is ready to be confirmed
        return 'Processing...'
      }

      const parsed = parseTimestamp(confirmationTime) as any
      return `About ${parsed.time}`
    },

    readyForProcessing () {
      const { confirmAt } = this.txDetails!
      // add 15 minutes
      const confirmationTime = confirmAt.add(BigNumber.from('54000')).toNumber()
      return this.txDetails && !!this.proof && Date.now() > confirmationTime /// && proof exists and fraud delay is over
    },

    requiresManualProcessing () {
      return this.txDetails && this.txDetails.status === 'Relayed' && requiresManualProcessing(this.txDetails.destination)
    },

    healthy () {
      const { confirmAt, timestamp } = this.txDetails!
      if (timestamp) {
        if (getHoursAgo(timestamp) <= 5) {
          return true
        }
      } else if (confirmAt.isZero()) {
        return true
      } else if (getHoursAgo(confirmAt.add(BigNumber.from('54000'))) <= 2) {
        return true
      }
      return false
    }
  }
})

</script>

<style scoped>
.tx-details {
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.alert {
  margin-bottom: 20px;
}
.alert__link {
  color: #fff;
}
.label {
  min-width: 50%;
  padding-left: 10px;
  font-weight: 700;
}
.row {
  display: flex;
}

.n-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

.details {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 20px;
}
.details__status {
  max-width: 200px;
  margin-top: 30px;
}
.details__table {
  width: 100%;
}

.manual_process {
  margin-top: 20px;
  margin-bottom: 20px;
}

.manual_process_text {
  margin-bottom: 16px;
  display: inline-block;
}

/* mobile */
@media only screen and (max-width: 600px) {
  .details {
    flex-direction: column;
  }
}
</style>
