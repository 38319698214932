<template>
  <div
    v-if="(showAlert || networkUnsupported) && mmConnected"
    class="network-alert"
  >
    <p>You are not on a supported network.  Supported networks: </p>
    <div class="network-alert__buttons">
      <n-button
        v-for="(network, i) in getAvailableNetworks()"
        :key="i"
        class="network-alert__button"
        type="default"
        color="white"
        size="small"
        round
        ghost
        @click="switchNetwork(network)"
      >{{ network }}</n-button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { NButton } from 'naive-ui'
import { useStore } from '../../store'
import { networks } from '../../config'

export default defineComponent({
  components: {
    NButton
  },

  data () {
    return {
      showAlert: false,
      availableNetworks: this.getAvailableNetworks()
    }
  },

  setup () {
    const store = useStore()

    return {
      // access a state in computed function
      connected: computed(() => store.state.wallet.connected),
      walletNetwork: computed(() => store.state.wallet.network),
      networkUnsupported: computed(() => store.state.wallet.networkUnsupported),
      store
    }
  },

  methods: {
    findNetwork () {
      const network = this.walletNetwork.toLowerCase()
      if (networks[network]) {
        this.showAlert = false
        return true
      } else {
        this.showAlert = true
        return false
      }
    },

    getAvailableNetworks () {
      return Object.keys(networks)
    },

    async switchNetwork (network: string) {
      await this.store.dispatch('setWalletNetwork', network)
    }
  },

  watch: {
    walletNetwork (newNetwork, oldNetwork) {
      if (newNetwork !== oldNetwork) {
        this.findNetwork()
      }
    }
  },

  computed: {
    mmConnected () {
      const { ethereum } = window as any
      if (!ethereum) {
        return false
      } else {
        return ethereum.isConnected()
      }
    }
  }
})
</script>

<style scoped>
.network-alert {
  box-sizing: border-box;
  z-index: 2;
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  opacity: 1;
  background-color: rgba(226, 64, 132, 0.7);
  padding: 0 20px;
}
.network-alert__buttons {
  display: flex;
}
.network-alert__button {
  margin-left: 10px;
  text-transform: capitalize;
}

/* mobile */
@media only screen and (max-width: 600px) {
  .network-alert {
    flex-direction: column;
    padding: 0 20px 15px 20px;
  }
}
</style>
