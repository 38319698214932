<template>
  <n-form-item :label="headerDestinationAddress" path="recipientAddress" :rule="rule">
    <n-input-group class="recipient">
      <n-input
        class="recipient__input"
        :value="recipientAddress"
        :placeholder="destinationAddressDefault"
        :disabled="!enableCustomRecipient || sending"
        @update:value="handleRecipientAddressChange"
      />
      <!-- recipient tooltip -->
      <n-tooltip placement="top">
        <template #trigger>
          <n-button
            class="recipient__button"
            type="primary"
            ghost
            :disabled="disableChangeButton || sending"
            @click="showRecipientTooltip = !showRecipientTooltip"
          >
            {{ changeButton }}
          </n-button>
        </template>
        <div class="recipient__tooltip">
          {{ disclaimerDestinationAddressChange }}
          <br>
          <n-button
            class="recipient__tooltip__button"
            size="tiny"
            color="#E24084"
            @click="enableChangeAddr"
          >{{ disclaimerAcceptButton }}</n-button>
        </div>
      </n-tooltip>
    </n-input-group>
  </n-form-item>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { NFormItem, NInput, NButton, NTooltip, NInputGroup } from 'naive-ui'
import { useStore } from '@/store'
import { isValidAddress } from '@/utils'
import { headerDestinationAddress, destinationAddressDefault, changeButton, disclaimerDestinationAddressChange, disclaimerAcceptButton } from '../../i18n/main.json'
import { COMPLIANT_ERROR_RESPONSE } from 'compliance-sdk'

export default defineComponent({
  components: {
    NFormItem,
    NInput,
    NButton,
    NTooltip,
    NInputGroup
  },

  data () {
    return {
      // user must accept that sending to a custom address
      // can result in a permanent loss of funds
      enableCustomRecipient: false,
      disableChangeButton: false,

      // copy
      headerDestinationAddress,
      destinationAddressDefault,
      changeButton,
      disclaimerDestinationAddressChange,
      disclaimerAcceptButton
    }
  },

  setup () {
    const store = useStore()
    // initial values
    const recipientAddress = computed(() => store.state.userInput.recipientAddress)
    const sending = computed(() => store.state.multiProvider.sending)

    return {
      store,
      recipientAddress,
      sending,
      rule: {
        trigger: ['input'],
        validator () {
          console.log('calling validator', recipientAddress.value)
          if (!isValidAddress(recipientAddress.value)) {
            return new Error('Address is invalid. Please input a valid address.')
          }

          if (store.state.sanctionedList.list.some(address =>
            recipientAddress.value.toLowerCase() === address.toLowerCase())) {
            return new Error(COMPLIANT_ERROR_RESPONSE)
          }

          return true
        }
      }
    }
  },

  methods: {
    handleRecipientAddressChange (address: string) {
      this.store.dispatch('setRecipientAddress', address)
    },

    // User acknowledges risks and enables changing recipient address
    enableChangeAddr () {
      this.enableCustomRecipient = true
      this.disableChangeButton = true
    }
  }
})
</script>

<style scoped>
.recipient {
  width: 100%
}
.recipient__tooltip {
  max-width: 200px !important;
}
.recipient__tooltip__button {
  margin: 10px 0;
}
</style>
