import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "buttons" }
const _hoisted_2 = {
  key: 1,
  class: "uppercase"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "disclaimer-modal__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_spin = _resolveComponent("n-spin")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_alert = _resolveComponent("n-alert")!
  const _component_n_divider = _resolveComponent("n-divider")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_n_button, {
        disabled: _ctx.sendDisabled(),
        class: "buttons__send",
        color: "#E24084",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (this.showModal = true))
      }, {
        default: _withCtx(() => [
          (_ctx.sending)
            ? (_openBlock(), _createBlock(_component_n_spin, {
                key: 0,
                class: "spinner",
                size: "small",
                stroke: "rgba(0,0,0,0.3)"
              }))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.sendButton), 1))
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _createVNode(_component_n_modal, { show: _ctx.showModal }, {
      default: _withCtx(() => [
        _createVNode(_component_n_card, {
          class: "disclaimer-modal",
          bordered: false,
          size: "huge"
        }, {
          default: _withCtx(() => [
            (_ctx.showNotPolygonDisclaimer())
              ? (_openBlock(), _createBlock(_component_n_alert, {
                  key: 0,
                  type: "warning",
                  class: "disclaimer-modal__alert"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Sending " + _toDisplayString(_ctx.walletToken.name) + " to Polygon will give you an optics representation token on Polygon and not the typical " + _toDisplayString(_ctx.walletToken.name) + " token. ", 1),
                    (_ctx.isUSDC())
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Did you mean to send USDC(PoS)?"))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.walletToken.name === 'ETH')
              ? (_openBlock(), _createBlock(_component_n_alert, {
                  key: 1,
                  type: "info",
                  class: "disclaimer-modal__alert"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" The native ETH asset must be wrapped before bridging. You will receive WETH at the destination address. ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.walletToken.name === 'MATIC')
              ? (_openBlock(), _createBlock(_component_n_alert, {
                  key: 2,
                  type: "info",
                  class: "disclaimer-modal__alert"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" The native MATIC asset must be wrapped before bridging. You will receive WMATIC at the destination address. ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.walletNetwork === 'Ethereum')
              ? (_openBlock(), _createBlock(_component_n_alert, {
                  key: 3,
                  type: "info",
                  class: "disclaimer-modal__alert"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Notice: Optics will no longer cover processing fees when returning to Ethereum. ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.destinationNetwork === 'Ethereum')
              ? (_openBlock(), _createBlock(_component_n_alert, {
                  key: 4,
                  type: "info",
                  class: "disclaimer-modal__alert"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" You are sending to Ethereum where you are expected to complete the processing transaction yourself. Be sure to have ETH on Ethereum before proceeding. ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_ctx.disclaimer) + " ", 1),
              _createVNode(_component_n_divider),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_n_button, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (this.showModal = false))
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Go Back")
                  ])),
                  _: 1
                }),
                _createVNode(_component_n_button, {
                  onClick: _ctx.send,
                  color: "#E24084",
                  ghost: ""
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Send")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}