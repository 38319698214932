<template>
  <div class="buttons">
    <n-button
      :disabled="sendDisabled()"
      class="buttons__send"
      color="#E24084"
      @click="this.showModal = true"
    >
      <n-spin v-if="sending" class="spinner" size="small" stroke="rgba(0,0,0,0.3)"/>
      <span v-else class="uppercase">{{ sendButton }}</span>
    </n-button>
  </div>

  <n-modal :show="showModal">
    <n-card class="disclaimer-modal" :bordered="false" size="huge">
      <n-alert
        type="warning"
        class="disclaimer-modal__alert"
        v-if="showNotPolygonDisclaimer()"
      >
        Sending {{walletToken.name}} to Polygon will give you an optics representation token on Polygon and not the typical {{walletToken.name}} token. <span v-if="isUSDC()">Did you mean to send USDC(PoS)?</span>
      </n-alert>
      <n-alert
        type="info"
        class="disclaimer-modal__alert"
        v-if="walletToken.name === 'ETH'"
      >
        The native ETH asset must be wrapped before bridging. You will receive WETH at the destination address.
      </n-alert>
      <n-alert
        type="info"
        class="disclaimer-modal__alert"
        v-if="walletToken.name === 'MATIC'"
      >
        The native MATIC asset must be wrapped before bridging. You will receive WMATIC at the destination address.
      </n-alert>
      <n-alert
        type="info"
        class="disclaimer-modal__alert"
        v-if="walletNetwork === 'Ethereum'"
      >
        Notice: Optics will no longer cover processing fees when returning to Ethereum.
      </n-alert>
      <n-alert
        type="info"
        class="disclaimer-modal__alert"
        v-if="destinationNetwork === 'Ethereum'"
      >
        You are sending to Ethereum where you are expected to complete the processing transaction yourself. Be sure to have ETH on Ethereum before proceeding.
      </n-alert>
      <div>
        {{ disclaimer }}
        <n-divider/>
        <div class="disclaimer-modal__buttons">
          <n-button @click="this.showModal = false">Go Back</n-button>
          <n-button @click="send" color="#E24084" ghost>Send</n-button>
        </div>
      </div>
    </n-card>
  </n-modal>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { NButton, NSpin, NModal, NDivider, NCard, NAlert } from 'naive-ui'
import { ethers } from 'ethers'

import { useStore } from '../../store'
import {
  getNetworkDomainIDByName,
  isValidAddress,
  isNativeToken,
  getMinAmount
} from '../../utils'
import { sendButton, sendButtonTooltip, disclaimer } from '../../i18n/main.json'

export default defineComponent({
  components: {
    NButton,
    NSpin,
    NDivider,
    NModal,
    NCard,
    NAlert
  },

  data () {
    return {
      showModal: false,
      minAmount: 0,

      // copy
      disclaimer
    }
  },

  setup () {
    const store = useStore()
    const walletAddress = computed(() => store.state.wallet.address)
    const walletNetwork = computed(() => store.state.wallet.network)
    const walletToken = computed(() => store.state.wallet.token)
    const sendAmount = computed(() => store.state.userInput.sendAmount)
    const recipientAddress = computed(() => store.state.userInput.recipientAddress)
    const destinationNetwork = computed(() => store.state.userInput.destinationNetwork)
    const sending = computed(() => store.state.multiProvider.sending)

    return {
      recipientAddress,
      walletAddress,
      walletNetwork,
      walletToken,
      sendAmount,
      destinationNetwork,
      sending,
      store,

      // copy
      sendButton,
      sendButtonTooltip
    }
  },

  async mounted () {
    await this.getMinAmt()
  },

  methods: {
    send () {
      this.showModal = false

      try {
        const payload = {
          isNative: isNativeToken(this.walletNetwork, this.walletToken),
          originNetwork: getNetworkDomainIDByName(this.walletNetwork),
          destNetwork: getNetworkDomainIDByName(this.destinationNetwork! as string),
          asset: this.walletToken.tokenIdentifier,
          amnt: ethers.utils.parseUnits(this.sendAmount.toString(), this.walletToken.decimals),
          recipient: this.recipientAddress
        }
        this.store.dispatch('send', payload)

        // set send amount to 0 if success
        this.store.dispatch('setSendAmount', 0)
      } catch (e) {
        console.error('send error:', e)
      }
    },

    sendDisabled () {
      // disable if token is native to the source chain
      if (getNetworkDomainIDByName(this.walletNetwork) === getNetworkDomainIDByName(this.walletToken.nativeNetwork)) {
        return true
      }

      // disable for bad amount
      if (!this.sendAmount || this.sendAmount === 0) return true

      // disable for bad recipient address
      if (!isValidAddress(this.recipientAddress)) return true

      // disable for sanctioned sender or recipient addresses
      if ([this.walletAddress, this.recipientAddress].some(address =>
        this.store.state.sanctionedList.list.some(sanctionedAddress =>
          sanctionedAddress.toLowerCase() === address.toLowerCase()))) { return true }

      // disable if less than minimum amount
      if (this.sendAmount < this.minAmount) return true

      // disable for null network or while sending
      return this.destinationNetwork === null || this.sending
    },

    async getMinAmt () {
      // return 0 if in development
      if (process.env.VUE_APP_OPTICS_ENVIRONMENT !== 'production') return 0

      try {
        const min = await getMinAmount(this.walletToken.coinGeckoId)
        this.minAmount = min
      } catch (e) {
        console.log(e)
        this.minAmount = this.walletToken.minAmt
      }
    },

    showNotPolygonDisclaimer () {
      if (this.destinationNetwork?.toLowerCase() === 'polygon') {
        return this.walletToken.nativeNetwork === 'ethereum'
      }
      return false
    },
    isUSDC () {
      return this.walletToken.name === 'USDC'
    }
  },

  watch: {
    walletToken () {
      this.getMinAmt()
    }
  }
})
</script>

<style scoped>
/* approve/send buttons */
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: var(--spacer);
}
.buttons__send {
  width: 150px;
}
.send__tooltip {
  max-width: 220px !important;
}

.disclaimer-modal {
  width: 90%;
  max-width: 500px;
}
.disclaimer-modal__alert {
  margin-bottom: 20px;
}
.disclaimer-modal__buttons {
  display: flex;
  justify-content: space-between;
}
</style>
